import { ImplementData, MachineData } from '../../data/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { CreationForm } from './equipmentForm'
import './equipment.scss'

interface EquipmentTilesProps {
    typeTitle: string
    typeIcon: IconDefinition
    equipment: MachineData[] | ImplementData[]
    selectedEquipmentId: string | null
    onCreate: (name: string, description: string) => Promise<void>
    onSelect: (equipment_id: string) => void
}

function EquipmentTiles({
    typeTitle,
    typeIcon,
    equipment,
    selectedEquipmentId,
    onCreate,
    onSelect,
}: EquipmentTilesProps) {
    return (
        <>
            <header>{typeTitle} Garage</header>
            <div className="equipment-tiles">
                <CreationForm typeIcon={typeIcon} typeTitle={typeTitle} onCreate={onCreate} />

                {equipment.map((equipment) => (
                    <div
                        key={equipment.id}
                        className={`equipment-tile ${
                            selectedEquipmentId === equipment.id ? 'selected' : ''
                        }`}
                        onClick={() => onSelect(equipment.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <div className="tile-header">
                            <div className="title">{equipment.name}</div>
                        </div>
                        <div className="equipment-body">
                            <div className="equipment-metadata">
                                <div>
                                    <div style={{ fontWeight: '600' }}>Description</div>

                                    <span
                                        style={{
                                            fontWeight: '300',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {equipment.description ? equipment.description : 'N/A'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export { EquipmentTiles }
