import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'
import { ImplementData, SoftwareVersions } from '../data/types'
import { useNetworkManager } from '../network/networkManager'
import { ConfirmationPopup } from '../components/misc/popup'
import { EquipmentTiles } from '../components/equipment/equipmentTiles'
import { faTrailer } from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from 'react-router-dom'
import { EquipmentOverview } from '../components/equipment/equipmentOverview'

function Implements() {
    const [_implements, setImplements] = useState<ImplementData[]>([])
    const _implementData = useSelector((state: RootState) => state._implements.data)
    const { _implements: _implementApi } = useNetworkManager()

    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [_implementIdToDelete, setImplementIdToDelete] = useState<string | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const selectedImplement = searchParams.get('equipmentSelected')

    useEffect(() => {
        setImplements(_implementData)
    }, [_implementData])

    const handleCreateImplement = async (name: string, description: string) => {
        await _implementApi.addImplement(name, description)
    }

    const handleDeleteImplement = async (_implement_id: string) => {
        setImplementIdToDelete(_implement_id)
        setIsDialogueVisible(true)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && _implementIdToDelete) {
            setLoading(true)
            await _implementApi.deleteImplement(_implementIdToDelete)
            setLoading(false)
            setImplementIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const handleEditImplement = async (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => {
        _implementApi.editImplement(id, name, description, softwareVersions)
    }

    const handleVehicleSelect = (implement_id: string) => {
        if (selectedImplement === implement_id) {
            searchParams.delete('equipmentSelected')
            setSearchParams(searchParams)
        } else {
            setSearchParams({ equipmentSelected: implement_id })
        }
    }

    const selectedEquipment =
        _implements.find((vehicle) => vehicle.id === selectedImplement) || null

    return (
        <div className="equipment-container-wrapper">
            <div className="equipment-container">
                {isDialogueVisible && (
                    <ConfirmationPopup
                        ChildComponent={<div>Are you sure you want to delete this _implement?</div>}
                        callback={deleteHandler}
                        loading={loading}
                    />
                )}
                <EquipmentTiles
                    typeTitle={'Implement'}
                    typeIcon={faTrailer}
                    equipment={_implements as ImplementData[]}
                    selectedEquipmentId={selectedImplement}
                    onCreate={handleCreateImplement}
                    onSelect={handleVehicleSelect}
                />

                {selectedEquipment && (
                    <EquipmentOverview
                        equipment={selectedEquipment}
                        typeTitle={'Vehicle'}
                        typeIcon={faTrailer}
                        onCreate={handleCreateImplement}
                        onEdit={handleEditImplement}
                        onDelete={handleDeleteImplement}
                    />
                )}
            </div>
        </div>
    )
}

export default Implements
