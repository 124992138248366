import { useState } from 'react'
import { ImplementData, MachineData, SoftwareVersions } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import './equipment.scss'
import { RenderImages } from '../recordedSessions/imageRenderer'

interface EquipmentOverviewProps {
    equipment: MachineData | ImplementData
    typeTitle: string
    typeIcon: IconDefinition
    onCreate: (name: string, description: string) => Promise<void>
    onDelete: (equipment_id: string) => Promise<void>
    onEdit: (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => Promise<void>
}

function EquipmentOverview({
    equipment,
    typeTitle,
    typeIcon,
    onCreate,
    onDelete,
    onEdit,
}: EquipmentOverviewProps) {
    const [editingEquipment, setEditingEquipment] = useState<string | null>(null)
    const [equipmentName, setEquipmentName] = useState<string>('')
    const [equipmentDescription, setEquipmentDescription] = useState<string>('')
    const [equipmentSoftware, setEquipmentSoftware] = useState<SoftwareVersions[]>([])

    const startEditing = (equipment: MachineData | ImplementData) => {
        setEditingEquipment(equipment.id)
        setEquipmentName(equipment.name)
        setEquipmentDescription(equipment.description || '')
        setEquipmentSoftware(equipment.softwareVersions)
    }

    const handleEditEquipment = async (id: string) => {
        await onEdit(id, equipmentName, equipmentDescription || '', equipmentSoftware)
        setEditingEquipment(null)
    }

    const cancelEditingEquipment = () => {
        setEditingEquipment(null)
    }

    const handleSoftwareVersionChange = (index: number, value: string) => {
        setEquipmentSoftware((prevSoftware) =>
            prevSoftware.map((software, i) =>
                i === index ? { ...software, version: value } : software
            )
        )
    }

    const RenderSoftware = (showSoftware: boolean, equipment: MachineData | ImplementData) => {
        return editingEquipment === equipment.id ? (
            <div className="software-version-editor">
                <span style={{ fontWeight: '600' }}>Software Versions</span>
                <div className="input-group">
                    {equipmentSoftware.map((version, index) => {
                        return (
                            <div key={index}>
                                <div>{version.name}: </div>
                                <input
                                    value={version.version}
                                    onChange={(e) =>
                                        handleSoftwareVersionChange(index, e.target.value)
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        ) : showSoftware ? (
            <div>
                <span style={{ fontWeight: '600' }}>Software Versions</span>
                <div>
                    {equipment.softwareVersions.length > 0
                        ? equipment.softwareVersions.map((version, index) => {
                              return (
                                  <div key={index}>
                                      {version.name}: {version.version}
                                  </div>
                              )
                          })
                        : 'N/A'}
                </div>
            </div>
        ) : null
    }

    return (
        <div className="equipment-overview">
            <div key={equipment.id} className="equipment-tile">
                {/* todo: add a the font awesome image here */}
                <div className="tile-header">
                    <div className="title">
                        <RenderImages
                            images={undefined}
                            deleteImage={() => {}}
                            imageUploading={false}
                            placeholderIcon={typeIcon}
                        />
                        {editingEquipment === equipment.id ? (
                            <input
                                type="text"
                                value={equipmentName}
                                onChange={(e) => setEquipmentName(e.target.value)}
                            />
                        ) : (
                            equipment.name
                        )}
                    </div>
                    <div className="controls">
                        {editingEquipment === equipment.id ? (
                            <>
                                <button
                                    className="edit-button"
                                    title={`Save ${typeTitle}`}
                                    onClick={() => handleEditEquipment(equipment.id)}
                                >
                                    Save
                                </button>
                                <button
                                    className="edit-button"
                                    title={`Cancel ${typeTitle}`}
                                    onClick={() => cancelEditingEquipment()}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="edit-button"
                                    title={`Edit ${typeTitle}`}
                                    onClick={() => {
                                        startEditing(equipment)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button
                                    className="delete-button"
                                    title={`Delete ${typeTitle}`}
                                    onClick={() => onDelete(equipment.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div className="equipment-body">
                    <div className="equipment-metadata">
                        <div>
                            <div style={{ fontWeight: '600' }}>Description</div>
                            {editingEquipment === equipment.id ? (
                                <div className="text-area-wrapper">
                                    <textarea
                                        value={equipmentDescription}
                                        onChange={(e) => setEquipmentDescription(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontWeight: '300',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {equipment.description ? equipment.description : 'N/A'}
                                </span>
                            )}
                        </div>
                        {RenderSoftware(true, equipment)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { EquipmentOverview }
